// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { trailingSlash } from '@/util/helpers'
import {
  layout,
  route,
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/signin',
    },
    layout('Default', [
      // route('Dashboard'),
      // Pages
      route('UserProfile', null, 'components/profile', { level: ['1', '2', '3', '4', '5'] }),
      // 매출관리
      route('Sales', 'Sales.vue', 'components/sales', { level: ['1', '2', '3', '4', '5'] }),
      // 일정관리
      route('Schedule', null, 'components/schedule', { level: ['1', '2', '3', '4', '5'] }),
      // 고객관리
      route('Account', null, 'components/account', { level: ['1', '2', '3', '4', '5'] }),
      route('Customer', null, 'components/customer', { level: ['1', '2', '3', '4', '5'] }),
      // Dashboard (메인)
      route('Dashboard', 'Dashboard.vue', 'components/dashboard', { level: ['1', '2', '3', '4', '5'] }),
      // 알림
      route('NoticeSendList', null, 'components/notice/sendlist', { level: ['1', '2', '3', '4', '5'] }),
      route('NoticeCfg', null, 'components/notice/cfg', { level: ['1', '2'] }),
      route('NoticeBoardList', null, 'components/notice/board', { level: ['1', '2'] }),
      // 제품관리
      route('Product', null, 'components/product', { level: ['1', '2', '3', '4', '5'] }),
      route('ProductList', null, 'components/productList', { level: ['1', '2', '3', '4', '5'] }),
      route('Order', null, 'components/order', { level: ['1', '2', '3', '4', '5', 'store'] }),
      route('ExchangeBack', null, 'components/exchangeBack', { level: ['1', '2', '3', '4', '5'] }),
      route('OrderList', null, 'components/orderList', { level: ['1', '2', '3', '4', '5'] }),
      route('Guarantee', null, 'components/guarantee', { level: ['1', '2', '3', '4', '5'] }),
      // 대리점관리
      route('StoreOrderList', null, 'components/storeOrderList', { level: ['1', '2', '3'] }),
      route('ProductCostManage', null, 'components/productCostManage', { level: ['1', '2'] }),
      route('StoreCalculate', null, 'components/storeCalculate', { level: ['1', '2', '3'] }),
      route('StockRelease', null, 'components/stockRelease', { level: ['1', '2', '3'] }),
      route('StoreExchangeBack', null, 'components/storeExchangeBack', { level: ['1', '2'] }),
      route('StoreManage', null, 'components/storeManage', { level: ['1', '2'] }),
      // 쿠폰관리
      route('CouponPolicyManage', null, 'components/couponPolicyManage', { level: ['1', '2'] }),
      route('CouponList', null, 'components/couponList', { level: ['1', '2'] }),
      route('StoreCouponUseList', null, 'components/storeCouponUseList', { level: ['1', '2', '3', '4', '5'] }),

      // Components
      route('Notifications', null, 'components/notifications', { level: ['1', '2', '3', '4', '5'] }),
      route('Icons', null, 'components/icons', { level: ['1', '2', '3', '4', '5'] }),
      route('Typography', null, 'components/typography', { level: ['1', '2', '3', '4', '5'] }),

      // Tables
      route('Regular Tables', null, 'tables/regular', { level: ['1', '2', '3', '4', '5'] }),

      // Maps
      route('Google Maps', null, 'maps/google', { level: ['1', '2', '3', '4', '5'] }),
    ]),
  layout('auth', [
    route('auth/SignIn', null, 'signin', { level: [] }),
    ]),
  layout('none', [
    route('ScheduleRsv', null, 'schedulersv/:storeNbr', { level: [] }),
    route('CouponRsvRequest', null, 'couponRsvRequest/:couponNbr/:mobile', { level: [] }),
    route('StoreScheduleRsv', null, 'storeSchedulersv/:storeNbr', { level: [] }),
    route('StoreScheduleRsv', null, 'storeSchedulersv', { level: [] }),
    route('ScheduleRsv', null, 'schedulersv', { level: [] }),
    route('GuaranteePop', null, 'guaranteePop/:guaranteeSrno/:phone', { level: [] }),
    ]),
  ],
})

// 메뉴 접근 권한 체크
router.beforeEach((to, from, next) => {
  if (to.meta.level || to.meta.level === undefined) {
    return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
  } else {
    const accessToken = sessionStorage.getItem('x-auth-token')

    if (accessToken !== null) {
      const jwt = require('jsonwebtoken')
      const { authLevel } = jwt.decode(accessToken)
      // 권한이 메뉴 접근 레벨보다 낮을 경우 접근권한 없음
      if (!to.meta.level.includes(authLevel)) {
        alert('접근권한이 없습니다.')
        return
      }
    }
  }

  return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
})

export default router
